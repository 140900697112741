import _ from 'lodash';
import * as type from './types';
export default (state = [], action) => {
  switch (action.type) {
    case type.FETCH_UNITS:
      return { ..._.mapKeys(action.payload, 'id') };
    case type.FETCH_UNIT:
      return { ...state, [action.payload.id]: action.payload };
    case type.CREATE_UNIT:
      return { ...state, [action.payload.id]: action.payload };
    case type.EDIT_UNIT:
      return { ...state, [action.payload.id]: action.payload };
    case type.DELETE_UNIT:
      return _.omit(state, action.payload);
    default:
      return state;
  }
};
