import _ from 'lodash';
import * as type from './types';
export default (state = {}, action) => {
  switch (action.type) {
    case type.FETCH_USERS:
      return { ..._.mapKeys(action.payload, 'id') };
    case type.FETCH_USER:
      return { [action.payload.id]: action.payload };
    case type.CREATE_USER:
      return { ...state, [action.payload.id]: action.payload };
    case type.EDIT_USER:
      return { ...state, [action.payload.id]: action.payload };
    case type.DELETE_USER:
      return _.omit(state, action.payload);
    default:
      return state;
  }
};
