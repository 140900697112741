import * as type from './types';

const INITIAL_DB_IDS = {
  userId: 0,
  invoiceId: '',
  UserPermissionsIsSelectAll: true,
  UserPermissionsTasks: [],
  UserPermissionsReports: []
};

export const db_Ids = (state = INITIAL_DB_IDS, action) => {
  switch (action.type) {
    case type.SET_INVOICE_ID:
      return { ...state, invoiceId: action.payload };
    case type.SET_USER_ID:
      return { ...state, userId: action.payload };
    case type.SET_USER_PERMISSIONS_SELECT_ALL:
      return { ...state, UserPermissionsIsSelectAll: action.payload };
    case type.SET_USER_PERMISSIONS_TASKS:
      return { ...state, UserPermissionsTasks: action.payload };
    case type.SET_USER_PERMISSIONS_REPORTS:
      return { ...state, UserPermissionsReports: action.payload };
    default:
      return state;
  }
};
