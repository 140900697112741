import axios from 'axios';

const axiosInstance = axios.create();
axiosInstance.interceptors.request.use(async (config) => {
  // const TOKEN = await localStorage.getItem('JWT_TOKEN');
  const TOKEN = localStorage.getItem('serviceToken');
  // const UserName = localStorage.getItem("USER_NAME");
  // for development
  // config.baseURL = 'https://localhost:7220/api';
  // config.baseURL = 'http://localhost:81/api';

  // for production
  config.baseURL = '/api';
  // config.timeout = "";
  config.timeoutErrorMessage = 'time out';
  config.headers = {
    Authorization: `Bearer ${TOKEN}`,
    'Content-Type': 'application/json;charset=UTF-8',
    Accept: 'application/json, text/plain',
    'Access-Control-Allow-Origin': '*',
    credentials: 'include',
    UserName: '',
    MachineName: ''
  };
  return config;
});
axiosInstance.interceptors.response.use(
  (res) => res,
  (err) => {
    console.log(err);
    if (err.response) {
      if (err.response.status === 401) {
        localStorage.setItem('JWT_TOKEN', '');
        // Warning(err.response.data, "Access Denied!");
      } else if (err.response.status === 500) {
        // Warning(err.response.data, "Failed!");
      }
      // else Warning(err.response.data, "Access Denied!");
    } else {
      // localStorage.setItem("JWT_TOKEN", "");
      // Warning(err, "Failed!");
    }
  }
);
export default axiosInstance;

// for development
// export const baseURL = 'https://localhost:7220/api';
// export const FileBaseURL = 'https://localhost:7220';

// for production
export const baseURL = '/api';
export const FileBaseURL = '';

//Build Date
export const BuildDate = 'BD:20231026';
export const Ok = (response) => response?.status === 200;
