import { openSnackbar } from '../snackbar';
import * as type from './types';

export const setInvoiceId = (value) => async (dispatch) => {
  dispatch({ type: type.SET_INVOICE_ID, payload: value });
};
export const setUserId = (id) => async (dispatch) => {
  dispatch({ type: type.SET_USER_ID, payload: id });
};
export const setUserPermissionsTasks = (data) => async (dispatch) => {
  dispatch({ type: type.SET_USER_PERMISSIONS_TASKS, payload: data });
};
export const setUserPermissionsReports = (data) => async (dispatch) => {
  dispatch({ type: type.SET_USER_PERMISSIONS_REPORTS, payload: data });
};
export const setUserPermissionsSelectAll = (data) => async (dispatch) => {
  dispatch({ type: type.SET_USER_PERMISSIONS_SELECT_ALL, payload: data });
};
export const alertSuccessSnackbar = (message) => async (dispatch) => {
  dispatch(
    openSnackbar({
      open: true,
      message: `${message ? message : 'Process completed successfully'}`,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
      variant: 'alert',
      alert: {
        color: 'success'
      },
      close: false
    })
  );
};
export const alertWarningSnackbar = (message) => async (dispatch) => {
  dispatch(
    openSnackbar({
      open: true,
      message: `${message ? message : 'something went wrong!'}`,
      anchorOrigin: { vertical: 'top', horizontal: 'right' },
      variant: 'alert',
      alert: {
        color: 'warning'
      },
      close: false
    })
  );
};
