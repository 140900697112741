import _ from 'lodash';
import * as type from './types';
export default (state = [], action) => {
  switch (action.type) {
    case type.FETCH_INVOICES:
      return { ..._.mapKeys(action.payload, 'uid') };
    case type.FETCH_INVOICE:
      return { [action.payload.uid]: action.payload };
    case type.FETCH_INVOICE_UID:
      return { ..._.mapKeys([action.payload], 'uid') };
    case type.CREATE_INVOICE:
      return { ...state, [action.payload.uid]: action.payload };
    case type.EDIT_INVOICE:
      return { ...state, [action.payload.uid]: action.payload };
    case type.DELETE_INVOICE:
      return _.omitBy(state, { id: action.payload });
    default:
      return state;
  }
};
