import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';

// project-imports
import Loader from 'components/Loader';
// import axios from 'utils/axios';
import baseurl, { Ok } from 'apis';
import { useDispatch } from 'react-redux';
import { fetchDefaultCompany } from 'store/reducers/companies/actions';
// import { useSelector } from 'react-redux';
function getPayload(token) {
  return JSON.parse(atob(token.split('.')[1]));
}

const chance = new Chance();

// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

const verifyToken = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  const decoded = jwtDecode(serviceToken);

  /**
   * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
   */
  return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {
  if (serviceToken) {
    localStorage.setItem('serviceToken', serviceToken);
    baseurl.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    // axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem('serviceToken');
    delete baseurl.defaults.headers.common.Authorization;
    // delete axios.defaults.headers.common.Authorization;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const dispatch2 = useDispatch();
  const [state, dispatch] = useReducer(authReducer, initialState);
  // const auth = useSelector(state.auth)
  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = localStorage.getItem('serviceToken');
        const isTokenVerified = verifyToken(serviceToken);
        if (serviceToken && isTokenVerified) {
          const payload = getPayload(serviceToken);
          if (payload) {
            setSession(serviceToken);
            const auth = {
              isLoggedIn: payload.IsAuthenticated === '1',
              isAdmin: payload.IsAdmin === '1',
              userId: payload.UserId,
              userName: payload.name,
              permissions: payload.Permissions,
              user: {
                name: payload.name,
                email: payload.name,
                id: payload.nbf
              }
            };
            dispatch({
              type: LOGIN,
              payload: auth
            });
            dispatch2({
              type: LOGIN,
              payload: auth
            });
          }
        } else {
          dispatch({
            type: LOGOUT
          });
          dispatch2({
            type: LOGOUT
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT
        });
        dispatch2({
          type: LOGOUT
        });
      }
    };

    init();
  }, [dispatch2]);

  const login = async (email, password) => {
    const formValues = {
      userName: email,
      password: password
      // name: 'user',
      // email,
      // id: '23423432423' + password
    };
    const response = await baseurl.post('/authenticate/login', formValues);
    if (Ok(response)) {
      const serviceToken = response.data;
      const payload = getPayload(serviceToken);
      if (payload) {
        dispatch2(fetchDefaultCompany());
        const user = {
          name: payload.name,
          email: payload.name,
          id: payload.nbf
        };
        const auth = {
          isLoggedIn: payload.IsAuthenticated === '1',
          userId: payload.UserId,
          userName: payload.name,
          permissions: payload.Permissions,
          isAdmin: payload.IsAdmin === '1',
          user: user
        };
        setSession(serviceToken);
        dispatch({
          type: LOGIN,
          payload: auth
        });
        dispatch2({
          type: LOGIN,
          payload: auth
        });
      }
    }
  };
  // const login = async (email, password) => {
  //   const response = await axios.post('/api/account/login', { email, password });
  //   const { serviceToken, user } = response.data;
  //   setSession(serviceToken);
  //   dispatch({
  //     type: LOGIN,
  //     payload: {
  //       isLoggedIn: true,
  //       user
  //     }
  //   });
  // };

  const register = async (email, password, firstName, lastName) => {
    // todo: this flow need to be recode as it not verified
    const id = chance.bb_pin();
    // const response = await axios.post('/api/account/register', {
    const response = await baseurl.post('/Authenticate/register', {
      // id,
      userId: '',
      userName: 'admin',
      email,
      password: 'admin'
      // firstName,
      // lastName
    });
    let users = response.data;

    if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
      const localUsers = window.localStorage.getItem('users');
      users = [
        ...JSON.parse(localUsers),
        {
          id,
          email,
          password,
          name: `${firstName} ${lastName}`
        }
      ];
    }

    window.localStorage.setItem('users', JSON.stringify(users));
  };

  const logout = async () => {
    setSession(null);
    // setSession(null);
    await dispatch({ type: LOGOUT });
    await dispatch2({ type: LOGOUT });
  };

  const resetPassword = async () => {};

  const updateProfile = () => {};

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile }}>{children}</JWTContext.Provider>;
};

JWTProvider.propTypes = {
  children: PropTypes.node
};

export default JWTContext;
