import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { FormattedMessage } from 'react-intl';

// project-imports
// import axios from 'utils/axios';

// initial state
const initialState = {
  openItem: ['dashboard'],
  openComponent: 'buttons',
  selectedID: null,
  drawerOpen: false,
  componentDrawerOpen: true,
  menu: {},
  error: null
};
const dashboardMenu = {
  dashboard: {
    id: 'group-dashboard',
    title: 'dashboard',
    type: 'group',
    icon: 'dashboard',
    children: [
      // {
      //   id: 'dashboard',
      //   title: 'dashboard',
      //   type: 'item',
      //   icon: 'dashboard',
      //   url: '/dashboard/default',
      //   breadcrumbs: false
      //   // children: [
      //   //   {
      //   //     id: 'default',
      //   //     title: 'default',
      //   //     type: 'item',
      //   //     url: '/dashboard/default',
      //   //     breadcrumbs: false
      //   //   },
      //   //   {
      //   //     id: 'analytics',
      //   //     title: 'analytics',
      //   //     type: 'item',
      //   //     url: '/dashboard/analytics',
      //   //     breadcrumbs: false
      //   //   }
      //   // ]
      // },
      {
        id: 'invoices',
        title: 'Invoices',
        url: '/invoices',
        type: 'item',
        icon: 'invoice',
        breadcrumbs: false
      },
      {
        id: 'companies',
        title: 'Company',
        type: 'item',
        url: '/companies',
        icon: 'company',
        breadcrumbs: false
      },
      {
        id: 'products',
        title: 'Products',
        type: 'item',
        url: '/products',
        icon: 'products',
        breadcrumbs: false
      },
      {
        id: 'locations',
        title: 'Sources',
        type: 'item',
        url: '/locations',
        icon: 'locations',
        breadcrumbs: false
      },
      {
        id: 'units',
        title: 'Units',
        type: 'item',
        url: '/units',
        icon: 'units',
        breadcrumbs: false
      },
      {
        id: 'customers',
        title: 'Customers',
        type: 'item',
        url: '/customers',
        icon: 'customer',
        breadcrumbs: false
      },
      {
        id: 'users',
        title: 'Users',
        type: 'item',
        url: '/users',
        icon: 'users',
        breadcrumbs: false
      },
      {
        id: 'reports',
        title: 'Reports',
        type: 'item',
        url: '/reports',
        icon: 'reports',
        breadcrumbs: false
      }

      // {
      //   id: 'invoice',

      //   title: 'Invoice',
      //   url: '/apps/invoice/dashboard',
      //   type: 'collapse',
      //   icon: icons.invoice,
      //   breadcrumbs: true,
      //   children: [
      //     {
      //       id: 'create',
      //       title: 'Create',
      //       type: 'item',
      //       url: '/apps/invoice/create'
      //     },
      //     {
      //       id: 'details',
      //       title: 'Details',
      //       type: 'item',
      //       url: '/apps/invoice/details/1'
      //     },
      //     {
      //       id: 'list',
      //       title: 'List',
      //       type: 'item',
      //       url: '/apps/invoice/list'
      //     },
      //     {
      //       id: 'edit',
      //       title: 'Edit',
      //       type: 'item',
      //       url: '/apps/invoice/edit/1'
      //     }
      //   ]
      // },
      // {
      //   id: 'components',
      //   title: 'components',
      //   type: 'item',
      //   url: '/components-overview/buttons',
      //   icon: 'components',
      //   target: true,
      //   chip: {
      //     label: 'new',
      //     color: 'primary',
      //     size: 'small',
      //     variant: 'combined'
      //   }
      // }
    ]
  }
};
// ==============================|| SLICE - MENU ||============================== //

export const fetchMenu = createAsyncThunk('', async () => {
  // const response = await axios.get('/api/menu/dashboard');
  // return response.data;
  return dashboardMenu;
});

const menu = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    activeItem(state, action) {
      state.openItem = action.payload.openItem;
    },

    activeID(state, action) {
      state.selectedID = action.payload;
    },

    activeComponent(state, action) {
      state.openComponent = action.payload.openComponent;
    },

    openDrawer(state, action) {
      state.drawerOpen = action.payload;
    },

    openComponentDrawer(state, action) {
      state.componentDrawerOpen = action.payload.componentDrawerOpen;
    },

    hasError(state, action) {
      state.error = action.payload;
    }
  },

  extraReducers(builder) {
    builder.addCase(fetchMenu.fulfilled, (state, action) => {
      state.menu = action.payload.dashboard;
    });
  }
});

export default menu.reducer;

export const { activeItem, activeComponent, openDrawer, openComponentDrawer, activeID } = menu.actions;
