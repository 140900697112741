// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { reducer as formReducer } from 'redux-form';
import storage from 'redux-persist/lib/storage';

// project-imports
import chat from './chat';
import calendar from './calendar';
import menu from './menu';
import snackbar from './snackbar';
import productReducer from './product';

import kanban from './kanban';
import invoice from './invoice';
import { masterCurrencies, masterProducts, ProductDetails, productMastersDDL, invoiceMastersDDL } from './masters/reducer';
import customers from './customers/reducer';
import products from './products/reducer';
import invoices from './invoices/reducer';
import { db_Ids } from './common/reducer';
import units from './units/reducer';
import locations from './locations/reducer';
import companies from './companies/reducer';
import users from './users/reducer';
import Reports, { ReportGroupsDDL, ReportsDDL, ReportDetails } from './reports/reducer';
import auth from './auth';
// ==============================|| COMBINE REDUCERS ||============================== //
const persistConfig = {
  key: 'root',
  storage
};
const reducers = combineReducers({
  chat,
  calendar,
  menu,
  snackbar,
  auth,
  masterCurrencies,
  productMastersDDL,
  invoiceMastersDDL,
  masterProducts,
  ProductDetails,
  db_Ids,
  customers,
  products,
  invoices,
  users,
  units,
  locations,
  companies,
  Reports,
  ReportGroupsDDL,
  ReportsDDL,
  ReportDetails,
  form: formReducer,
  product: productReducer,
  kanban,
  invoice
});

export default persistReducer(persistConfig, reducers);
