import * as type from './types';

export const masterCurrencies = (state = [], action) => {
  switch (action.type) {
    case type.FETCH_CURRENCIES:
      return _.mapKeys(action.payload, 'value');
    default:
      return state;
  }
};

export const masterProducts = (state = [], action) => {
  switch (action.type) {
    case type.FETCH_PRODUCTS:
      return _.mapKeys(action.payload, 'value');
    default:
      return state;
  }
};
export const ProductDetails = (state = [], action) => {
  switch (action.type) {
    case type.FETCH_PRODUCT_DETAILS:
      return _.mapKeys(action.payload, 'productCode');
    default:
      return state;
  }
};
export const invoiceMastersDDL = (state = [], action) => {
  switch (action.type) {
    case type.FETCH_INVOICE_MASTER_DDL:
      return action.payload;
    default:
      return state;
  }
};
export const productMastersDDL = (state = [], action) => {
  switch (action.type) {
    case type.FETCH_PRODUCT_MASTER_DDL:
      return action.payload;
    default:
      return state;
  }
};
