import * as type from './types';

import baseurl, { Ok } from '../../../apis';
import { alertSuccessSnackbar, alertWarningSnackbar } from '../common/actions';

export const createCompany = (formValues) => async (dispatch) => {
  const response = await baseurl.post(`/Companies`, formValues);
  if (Ok(response)) {
    dispatch({ type: type.CREATE_COMPANY, payload: response.data });
    dispatch(alertSuccessSnackbar('Company added successfully'));
  }
};
export const fetchDefaultCompany = () => async (dispatch) => {
  const response = await baseurl.get(`/Companies/DefaultCompany`);
  if (Ok(response)) {
    dispatch({ type: type.FETCH_COMPANY, payload: response.data });
  }
};
export const fetchCompany = (id) => async (dispatch) => {
  const response = await baseurl.get(`/Companies/${id}`);
  if (Ok(response)) {
    dispatch({ type: type.FETCH_COMPANY, payload: response.data });
  }
};
export const fetchCompanies = () => async (dispatch) => {
  const response = await baseurl.get(`/Companies`);
  if (Ok(response)) {
    dispatch({ type: type.FETCH_COMPANIES, payload: response.data });
  }
};
export const editCompany = (formValues) => async (dispatch) => {
  const response = await baseurl.patch(`/Companies`, formValues);

  if (Ok(response)) {
    dispatch({ type: type.EDIT_COMPANY, payload: response.data });
    dispatch(alertSuccessSnackbar('Company updated successfully'));
  }
};
export const deleteCompany = (id) => async (dispatch) => {
  const response = await baseurl.delete(`/Companies/${id}`);
  if (Ok(response)) {
    if (response.data) {
      dispatch({ type: type.DELETE_COMPANY, payload: id });
      dispatch(alertSuccessSnackbar('Company deleted successfully'));
    } else {
      dispatch(alertWarningSnackbar('Company can not be deleted, some invoices created with this!'));
    }
  }
};
