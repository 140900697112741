// project-imports
// import applications from './applications';
// import widget from './widget';
// import formsTables from './forms-tables';
// import chartsMap from './charts-map';
// import support from './support';
// import pages from './pages';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  // items: [widget, applications, formsTables, chartsMap, pages, support]
  items: []
};

export default menuItems;
